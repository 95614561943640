<template>
  <van-index-bar class="placeList">
    <template v-for="(item, index) in alphabet">
      <van-index-anchor :key="index" :index="item.initial"/>
      <van-cell v-for="(val, i) in item.cells" :key="val.value" :title="val.label" @click="checkPlace(val.value, val.label, val.subarea)"/>
    </template>
  </van-index-bar>
</template>

<script>
export default {
  props: ['org'],
  data () {
    return {
      rens: [],
      alphabet: []
    }
  },
  methods: {
    getPlaceList () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/place/info/list'),
        method: 'get',
        params: this.$http.adornParams({
          page: 1,
          limit: -1,
          orgId: this.org
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.rens = data.page.list.map(item => {
            return {
              value: item.id + '',
              label: item.name,
              address: item.address,
              spell: item.licenseNameSpell
            }
          })
          this.getInfo()
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    getInfo () {
      const alphabet = []
      'ABCDEFGHIJKLMNOPQRSTUVWXYZ#'.split('').forEach((initial) => {
        const cells = this.rens.filter((ren) => (ren.spell.charAt(0).toUpperCase() === initial) || ((initial === '#') && (ren.spell.charAt(0).match('^[0-9]*$') !== null)))
        if (cells.length > 0) {
          alphabet.push({
            initial,
            cells,
          })
        }
      })
      this.alphabet = alphabet
    },
    checkPlace (value, label, subarea) {
      let msg = {
        placeValue: value,
        buildingId: 0,
        addrId: value,
        address: label,
        placeTitle: label,
        subarea: subarea
      }
      this.$emit('checkPlace', msg)
    }
  },
  created() {
    this.getPlaceList()
  }
}
</script>

<style scoped>
.placeList {
  width: 100%;
  position: absolute;
  top: 0;
}
::v-deep .van-index-anchor {
  background-color: #F5F5F5;
}
</style>
